import React, { useState, useEffect } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import WishList from "../ProductListing/WishList";
import { calculateDiscount, RupeesIcon } from "../../utils/constants";
import moment from "moment";
import { Authorization } from "../../utils/Authorization";
import { useToasts } from "react-toast-notifications";
import { red } from "@material-ui/core/colors";
import { useSelector } from "react-redux";
import { timeFunction } from "../Profile/MainProfile/Notification/function";
import { isShowBuyButton } from "../../utils/commonFunctions";
import axios from "axios";
import { API_URL, API_PREFIX } from "../../config2";
import Toast from 'light-toast'

const formattedDate = (createdAt) => {
  const createdAtT = moment.utc(createdAt);
  return createdAtT.format("DD/MM/YYYY h:mm a");
};

const Card = (props) => {
  const getNameSize = () => {
    let name = props.productName.replace(/(&nbsp;|<([^>]+)>)/gi, "");
    return (
      <div className="product-name">
        <strong className="prod-name">
          {props && props.userdata && props.userdata.companyName}
        </strong>
        <h3 className="cat-sub-head">
          {name == "undefined" ? "Testing" : name}{" "}
          <span class="detail-catg">
            {props.productGrade ? "(" + props.productGrade + ")" : null}
          </span>
        </h3>
      </div>
    );
  };

  return (
    <div
      className={
        props.className
          ? props.className
          : "costume-box  costume-box-wrap new-card-custom"
      }
    >
      <div className="costume-block">
        <Link
          to={`/product/${props.categoryData.name}/${props._id}/?cat=${props.categoryData.slug}`}
          className={props.quantity <= 0 ? "sold" : ""}
        >
          {props.deletedAt ? (
            <div className="no-deliver-item">DISCONTINUED</div>
          ) : props.Moderate ? (
            <div className="no-deliver-item">Unavailable</div>
          ) : props.quantity <= 0 ? (
            <span>SOLD</span>
          ) : null}
        </Link>
        {/* {
          props.wishlistIcon ? (
            <div className="costume-action">
              {props.label ? <span className="new">{props.label}</span> : null}
              <WishList pid={props.id} wishlistId={props.wishlist_id} />
            </div>
          ) : null
        } */}
        <Link
          className="product-pack product-pack-img"
          to={`/product/${props.categoryData.name}/${props._id}/?cat=${props.categoryData.slug}`}
        >
          <div className="costumes costumes-img">
            <img src={props.default_image} alt="costume-img" />
            {/* <img src="/assets/images/metals-img.svg" alt="costume-img" /> */}
          </div>
          {getNameSize()}
        </Link>
      </div>
      <Link
        to={`/product/${props.categoryData.name}/${props._id}/?cat=${props.categoryData.slug}`}
        className="sub-custome-new"
      >
        <MainCard getNameSize={getNameSize} {...props} />
      </Link>
    </div>
  );
};

export default withRouter(Card);

const MainCard = (props) => {
  const [getProfileStatus, setGetProfileStatus] = useState();
  const { addToast } = useToasts();
  const [productPrice, setProductPrice] = useState("");
  const [insuarancePrice, setInsuarancePrice] = useState("");
  const [loadingCharing, setloadingCharing] = useState("");
  const [gst, setGst] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");

  const userData = useSelector(
    (state) => state.userProfile.userProfileBanner.userdata
  );
  var history = useHistory();
  const buyNowHandler = (e, url, productDetail) => {
    e.preventDefault();
    let userId = productDetail.userdata._id;
    let selfIdId = productDetail.selfUserData._id;

    if (userId == selfIdId) {
      addToast("You cann't buy your own product", {
        appearance: "error",
        autoDismissTimeout: 2500,
      });

      return false;
    }
    let data = JSON.parse(JSON.stringify(productDetail));
    let checkLoginStatus = Authorization();
    if (checkLoginStatus.status) {
      history.push(url, { data, page: "Listing" });
    } else {
      //props.setLoginPopupStatus({ status: true, screenToShow: "login" });
    }
  };

  useEffect(() => {
    setCreatedAt(props.createdAt);
    setUpdatedAt(props.updatedAt);
    if (props.productPrice) {
      setProductPrice(parseInt(props.productPrice.$numberDecimal));
    }
    setInsuarancePrice(
      parseInt(props.insuranceCharge && props.insuranceCharge.$numberDecimal)
    );
    setloadingCharing(
      parseInt(props.loadingCharge && props.loadingCharge.$numberDecimal)
    );
    if (props.productPrice) {
      setGst(
        parseInt(
          parseFloat(
            (parseInt(props.productPrice.$numberDecimal) / 100) *
              parseInt(props.gst.$numberDecimal)
          ).toFixed(2)
        )
      );
    }
    let status = localStorage.getItem("approved");
    //setGetProfileStatus(status);
    setGetProfileStatus(status);
  }, [props]);

  const handleRFQClick = (productId, companyData, productName, productPrice, categoryData, productSize, deliveryType, productGrade, deliveryDateFrom, deliveryDateTo, packaging, paymentType) => {
    const companyId = companyData._id;
    const path = `/make-rfq-offer/${productId}/${companyId}`;
    history.push({
      pathname: path,
      state: { productId, companyData,  productName, productPrice, categoryData, productSize, deliveryType, productGrade, deliveryDateFrom, deliveryDateTo, packaging, paymentType }
    });
  };

  const handleRequestPrice = async () => {
    const url = `${API_URL}${API_PREFIX}product/request-price`;
    try {
      const response = await axios.post(url, { _id: props._id, sellerName: props.userdata.companyName, buyerId: props.selfUserData._id, productName: `${props.productName}(${props.productGrade})`, type: props.categoryData.name, size: props.productSize });
      Toast.success("Thank you for your interest, we will get back to you soon.", 3000);
    } catch (error) {
      console.log(error)
      Toast.fail("Something went wrong. Please try again later.", 3000);
    }
  }

  return (
    <>
      <div className="costume-info my-list my-list-wrap">
        <div className="costume-box-top main-offer-box">
          <div className="costume-ltl costume-left-block">
            <div className="costume-box-wrapper">
              <div className="box-wrp">
                <span className="name">Size:</span>
                <span className="value">
                  {props.productSize} mm{" "}
                  {props.process != null ? `(${props.process})` : null}
                </span>
              </div>
              <div className="box-wrp">
                <span className="name">Qty (mt):</span>
                <span className="value">{props.productWeight}</span>
              </div>
            </div>
            <div className="costume-box-bottom delivery-wrapper-payment">
              <div className="box-wrp">
                <span className="name">Payment Terms</span>
                <span className="value">
                  {props.paymentType}
                  {props.otherPayType ? ` (${props.otherPayType})` : null}
                </span>
              </div>
              {props.deliveryDateFrom && <div className="box-wrp delivery-window">
                <span className="name">Delivery Window:</span>
                <span className="value">
                  {moment(props.deliveryDateFrom).format("Do MMM")} -{" "}
                  {moment(props.deliveryDateTo).format("Do MMM")}
                </span>
              </div>}
              <div className="box-wrp delivery-window">
                <span className="name">Delivery:</span>
                {props.cityData.map((item, index) => {
                  return (
                    index === 0 && (
                      <span className="value">
                        {props.deliveryType} {item.cityName}
                      </span>
                    )
                  );
                })}
              </div>
            </div>
          </div>

          {getProfileStatus == "pending" || productPrice == '' ? null : (
            <>
              <div className="costume-rtl offer-card-wrap costume-right-block">
                <div className="exclude-gst-price card-total-price">
                  <div className="offer-price card-total-price">
                    <h5>
                      <label class="font12 offer-price-head">Offer Price</label>
                    </h5>
                    <h5
                      className={
                        isShowBuyButton(props)
                          ? "actual-price"
                          : "actual-price actual-price-expired"
                      }
                    >
                      &#8377; {productPrice}/<span className="unit-mt">mt</span>
                    </h5>
                    {!isShowBuyButton(props) && (
                      <div className="created-date posted-date">
                        <span className="price-expired">Price Expired</span>
                      </div>
                    )}
                    <label class="font10">(+ 18% GST)</label>
                    {isShowBuyButton(props) && props.expiryDate && (
                          <div>
                            <label class="font10">
                              Valid until:
                            </label>
                            <div>
                            <label class="font10">
                              {formattedDate(props.expiryDate) || ""}
                            </label>
                            </div>
                          </div>
                     )}
                    {/* <label class="font10">*GST (18%) excluded</label> */}
                    {/* <h5>
                      <label class="font12">Loading+insurance</label>
                    </h5>
                    <h5 className="actual-price">&#8377;{insuarancePrice + loadingCharing}</h5> */}
                    {/* <h5>&#8377;{gst}</h5> */}
                  </div>
                  <div className="created-date posted-date">
                    <i className="icon-time"></i>
                    <span className="create-time">
                      {timeFunction(new Date(updatedAt))}
                    </span>
                  </div>
                </div>
                {/* <div className="offer-btn-wrapper">
                  {userData && userData.userType == "Seller" ?
                    null :
                    <Link
                      className="offer-btn-wrp cart-offer"
                      to="#"
                      onClick={(e) => buyNowHandler(e, `/checkout?pid=${props._id}&category=${props.categoryData.slug}&type=${props.categoryData.name}`, props)}
                    >
                      <button className="accept-offer-btn" disabled={getProfileStatus == "pending" ? true : false}>Buy Now</button>
                    </Link>
                  }
                </div> */}
              </div>
            </>
          )}
        </div>

        <div className="offer-btn-wrapper new-offer-btn">
          {userData && userData.userType == "Seller" ? null : (
            <>
              {isShowBuyButton(props) && productPrice !='' && (
                <Link
                  className="offer-btn-wrp cart-offer"
                  // to="#"
                  // onClick={(e) =>
                  //   buyNowHandler(
                  //     e,
                  //     `/checkout?pid=${props._id}&category=${props.categoryData.slug}&type=${props.categoryData.name}`,
                  //     props
                  //   )
                  // }
                  to={`/make-offer/${props._id}`}
                >
                  <button
                    className="accept-offer-btn"
                    disabled={getProfileStatus == "pending" ? true : false}
                  >
                    Add to Cart
                  </button>
                </Link>
              )}
            {(!isShowBuyButton(props) || productPrice == 0) && (
                <Link onClick={(e) => {e.preventDefault(); handleRequestPrice(); }}>
                  <button className="accept-offer-btn">Request Price</button>
                </Link>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
