import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import Select from "react-select";
import { isEmpty } from "lodash";
import { format } from "date-fns";
import { RupeesIcon, ButtonLoader } from "../../../../utils/constants";
import moment from "moment";
import { Rating } from "../MyOrder/listingProduct";
import PendingTab from "./pendingTab";
import { priceWithSymbol } from "../../../../utils/constants";
import { useSelector } from "react-redux";
import swal from "sweetalert";
// import { verifyProfile,getUserAddedProduct ,getproductList } from '../../../store/actions/profile';

const ListingProduct = (props) => {
  const sellerName = useSelector(
    (state) => state.userInfo.loggedUserInfo.userName
  );
  let location = useLocation();
  let history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [deliveryType, setDeliveryType] = React.useState({
    value: "Internal",
    label: "With MetalsBuy",
  });
  const [deliveryOption, setDeliveryOption] = React.useState([
    { value: "Internal", label: "With MetalsBuy" },
    { value: "External", label: "Self" },
  ]);
  const [isDeliveryFree, setIsDeliveryFree] = React.useState(false);
  const [deliveryCharges, setDeliveryCharges] = React.useState("");
  const [deliveryTime, setDeliveryTime] = React.useState("");
  const [Quantity, setQuantity] = React.useState(1);
  // const [ShippingMethod, setShippingMethod] = React.useState("");
  const [sellPrice, setSellPrice] = React.useState("");
  const [selectedProd, setSelectedProd] = React.useState({});
  const [index, setIndex] = React.useState(0);
  const [buttonLoader, setButtonLoader] = React.useState(false);

  // React.useEffect(() => {
  //   if (!isEmpty(props.productObject)) {

  //     setProductList(props.productObject.data);
  //   }
  // }, [props.productObject]);

  React.useEffect(() => {
    // if (!props.myProduct) {
    props.loadMore({
      page: 1,
      type: props.type,
    });
    // }
  }, [props.type]);

  const checkSelectedProd = (e, index) => {
    if (e.target.checked) {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        buttons: true,
      }).then((willDelete) => {
        if (willDelete) {
          setOpen(true);
          setSelectedProd(props.myProduct.product[index]);
          setIndex(index);
        }
      });
    } else {
      let prod = props.myProduct.product[index];
      let payload = {
        productId: prod.product_detail._id,
        orderItemId: prod._id,
      };

      swal({
        title: "Are you sure?",
        text: "You want to remove it from list",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          props.removeFromReList(payload, (callback) => {
            window.location.reload();
          });
        } else {
        }
      });
    }
  };

  const relistHandler = (e) => {
    e.preventDefault();
    const userId = localStorage.getItem("userId");
    let payload = {
      current_store_price:
        selectedProd.product_detail.Price.current_store_price,
      sell_price: sellPrice,
      offer_price: selectedProd.product_detail.Price.offer_price,
      saving_amount: selectedProd.product_detail.Price.saving_amount,
      shipping_type: deliveryType.value,
      delievery_charge: deliveryCharges,
      deliveryTime: deliveryTime,
      productId: selectedProd.product_detail._id,
      sellerId: userId,
      orderItemId: selectedProd._id,
      Quantity,
    };
    props.reListProd(payload, (callback) => {
      window.location.reload();
    });
    // let relist = this.state.productList[this.state.index].reList;
    // this.state.productList[this.state.index].reList = !relist;
    // this.setState({
    //     open:false,
    //     productList:this.state.productList,
    //     isDeliveryFree : false,
    //     deliveryCharges:"",
    //     deliveryTime:"",
    //     ShippingMethod:"",
    //     sellPrice:"",
    //     productList:[],
    //     selectedProd:{},
    //     index:0
    // })
  };

  const loadMore = () => {
    setButtonLoader(true);
    props.loadMore({
      userId: props.userId,
      page: props.myProduct.page + 1,
      type: props.type,
    });
    setTimeout(() => {
      setButtonLoader(false);
    }, 700);
  };

  const closePopup = () => {
    setOpen(false);
  };

  const checkboxHandler = () => {
    setIsDeliveryFree(!isDeliveryFree);
    setDeliveryCharges("");
  };

  const timeBy = (data) => {
    let day = moment(data).format("Do MMMM Y");
    let time = moment(data).format("h:mm a");
    return `${day} by ${time}`;
  };

  const getClass = (data) => {
    if (data === "OrderComplete") return "confirm order-status-box";
    else if (data === "Processed") return "process order-status-box";
    else if (data === "Cancelled") return "cancel order-status-box";
    else if (data === "Returned") return "return order-status-box";
    else return "order-status-box";
  };

  const goTodetailPage = (e, payload) => {
    e.preventDefault();
    if (props.type !== "Cancelled") {
      history.push({
        pathname: `/profile/myorder/order-detail`,
        state: { ...location.state, payload },
      });
    } else {
      return;
    }
  };

  return (
    <div className="plp-product-screen">
      {props.type === "Pending" ? (
        <PendingTab {...props.myProduct} />
      ) : (
        !isEmpty(props.myProduct) &&
        props.myProduct.product.length > 0 &&
        props.myProduct.product.map((item, i) => {
          if (item._id) {
            // const userRating = item.reviewData[0] ? item.reviewData[0].rating : 0;
            const payload = {
              orderId: item._id,
              // productId: item._id,
              view: "seller",
              type: props.type,
            };
            let grand_total =
              typeof item.grand_total === "object" && item.grand_total !== null
                ? item.grand_total.$numberDecimal
                : item.grand_total;
            console.log("location.state", location.state, payload, props.type);
            return (
              <>
                {props.type === "Processed" ? (
                  <>
                    <div className="buy-prod buy-product-wrap">
                      <div className="est-deliveries order-east-deliveries">
                        <div className="prod-img">
                          <Link
                            to={(location) => ({
                              pathname: `/profile/seller-order/order-detail/${payload.orderId}/${props.type}`,
                              state: { ...location.state, payload },
                            })}
                          >
                            <img
                              src={item.product_detail.default_image}
                              alt="img"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="prod-detail product-orderinfo product-info-detail-wrap">
                        <Link
                          className="prod-link"
                          key={i}
                          to={(location) => ({
                            pathname: `/profile/seller-order/order-detail/${payload.orderId}/${payload.type}`,
                            state: { ...location.state, payload },
                          })}
                        >
                          <div className="home-detail qty-pricing">
                            <div className="home-details">
                              <span className="prod-text bold quty">
                                {item.product_detail.productName}{" "}
                                {item.product_detail.productGrade}&nbsp;
                                {/* Name:&nbsp; */}
                              </span>
                              {/* <span className="prod-text">
                                {item.product_detail.productName}
                              </span> */}
                            </div>
                            <div className="home-details">
                              <span className="prod-text bold quty">
                                Quantity:&nbsp;
                              </span>
                              <span className="prod-text">
                                {item.order_item_qty}mt{" "}
                                {/*24-oct - {item.order_item_qty}*/}
                              </span>
                            </div>
                            {item.unit_price && (
                              <div className="home-details">
                                <span className="prod-text bold quty">
                                  Price (PMT):&nbsp;
                                </span>
                                <span className="prod-text">
                                  {RupeesIcon}
                                  {item.offerDetails ? item.offerDetails.final_offer_price: item.unit_price}
                                </span>
                              </div>
                            )}
                            <div className="pro-price">
                              <span className="prod-text bold quty">
                                Total Price:&nbsp;
                              </span>
                              <span className="prod-text">
                                {RupeesIcon}{" "}
                                {Math.trunc(item.total_order_amount)}
                              </span>
                            </div>
                          </div>
                          {item.customerData &&
                            item.customerData.length > 0 &&
                            item.customerData[0].companyName && (
                              <div className="home-detail qty-pricing">
                                <div className="home-details">
                                  <span
                                    className="prod-text bold sub-name"
                                    style={{ marginLeft: "0" }}
                                  >
                                    Buyer name:&nbsp;
                                  </span>
                                  <span className="prod-text value">
                                    {item.customerData[0].companyName}
                                  </span>
                                </div>
                              </div>
                            )}
                        </Link>
                      </div>

                      <div className="prod-title prod-toggle product-detail-completed rtl-delivery-information delivery-details-right">
                        <div className="del-details ">
                          <Link
                            key={i}
                            to={(location) => ({
                              pathname: `/profile/seller-order/order-detail/${payload.orderId}/${payload.type}`,
                              state: { ...location.state, payload },
                            })}
                            className="completed-success"
                          >
                            {props.type === "Completed" ? (
                              <>
                                <strong className="delivery-date">
                                  Delivered on{" "}
                                  {moment(item.current_status_time).calendar({
                                    sameDay: "h:mm a",
                                    nextDay: "[Tomorrow] h:mm a",
                                    nextWeek: "dddd",
                                    lastDay: "[Yesterday] h:mm a",
                                    lastWeek: "DD/MM/YYYY h:mm a",
                                    sameElse: "DD/MM/YYYY h:mm a",
                                  })}
                                </strong>
                              </>
                            ) : props.type === "Processed" ? (
                              <>
                                <strong
                                  className="created-date posted-date order-date-page"
                                  style={{ marginBottom: "20px" }}
                                >
                                  Received on:{" "}
                                  {/* {moment(item.current_status_time).calendar({
                                    sameDay: "h:mm a",
                                    nextDay: "[Tomorrow] h:mm a",
                                    nextWeek: "dddd",
                                    lastDay: "[Yesterday] h:mm a",
                                    lastWeek: "DD/MM/YYYY h:mm a",
                                    sameElse: "DD/MM/YYYY h:mm a",
                                  })} */}
                                  {moment(item.updatedAt).calendar({
                                    sameDay: "DD/MM/YYYY h:mm a",
                                    nextDay: "DD/MM/YYYY h:mm a",
                                    nextWeek: "DD/MM/YYYY h:mm a",
                                    lastDay: "DD/MM/YYYY h:mm a",
                                    lastWeek: "DD/MM/YYYY h:mm a",
                                    sameElse: "DD/MM/YYYY h:mm a",
                                  })}
                                  {/*24-sept{moment(item.createdAt).format("MMMM Do YYYY h:mm a")} */}
                                </strong>
                              </>
                            ) : props.type === "Cancelled" ? (
                              <>
                                <strong className="delivery-date">
                                  Cancelled on{" "}
                                  {moment(item.current_status_time).calendar({
                                    sameDay: "DD/MM/YYYY h:mm a",
                                    nextDay: "DD/MM/YYYY h:mm a",
                                    nextWeek: "DD/MM/YYYY h:mm a",
                                    lastDay: "DD/MM/YYYY h:mm a",
                                    lastWeek: "DD/MM/YYYY h:mm a",
                                    sameElse: "DD/MM/YYYY h:mm a",
                                  })}
                                </strong>
                                <span> Your item has been cancelled </span>
                              </>
                            ) : props.type === "Returned" ? (
                              <>
                                <strong>
                                  Return request on:{" "}
                                  {moment().format("Do MMMM YYYY h:mm a")}
                                </strong>
                                <span> Item returned</span>
                              </>
                            ) : null}

                            {item.odid && (
                              <span className="date-wrp">
                                Order ID: {item.odid}{" "}
                              </span>
                            )}
                            <div className="date-wrp main-wrp-order-status">
                              <div className="order-status-wrapper-box">
                                <div className="date-wrp order-status-date-wrap">
                                  Order status:&nbsp;
                                </div>
                                <span
                                  className={
                                    getClass(
                                      item.current_status || item.order_status
                                    ) + " order-request-state date-wrp"
                                  }
                                >
                                  {
                                    item.shippingData[0] && item.order_status !== 'GoodsReceived' ? 'Dispatching' :
                                    item.order_status === 'GoodsReceived' ? 'Completed' : 'Processing'
                                  }
                                </span>
                              </div>
                            </div>

                            <div className="rating-msg rating-msz-wrapper">
                              {(props.type === "Completed" ||
                                props.type === "Returned") && (
                                <div className="rating-star rating-star-new">
                                  <ul className="ratings">
                                    <Rating
                                      val={
                                        item.reviewData[0]
                                          ? item.reviewData[0].rating
                                          : 0
                                      }
                                    />
                                  </ul>
                                </div>
                              )}

                              {item.customer_details.customer_id !==
                                item.seller_details._id && (
                                <Link
                                  className="shop-now msg-btn rating-msz-btn"
                                  to={{
                                    pathname: "/chats/all",
                                    state: {
                                      chatPayload: {
                                        sender_id:
                                          item.customer_details.customer_id,
                                        product_id: item.product_id,
                                      },
                                    },
                                  }}
                                  data-count={item.messageCount}
                                >
                                  <i
                                    className="fas fa-envelope icon-chat-new"
                                    data-count={0}
                                  />
                                </Link>
                              )}
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="buy-prod buy-product-wrap">
                      <div className="est-deliveries order-east-deliveries">
                        {props.type !== "Cancelled" ? (
                          <div className="prod-img">
                            <Link
                              to={(location) => ({
                                pathname: `/profile/seller-order/order-detail/${payload.orderId}/${props.type}`,
                                state: { ...location.state, payload },
                              })}
                            >
                              <img
                                src={item.product_detail.default_image}
                                alt="img"
                              />
                            </Link>
                          </div>
                        ) : (
                          <div className="prod-img">
                            <img
                              src={item.product_detail.default_image}
                              alt="img"
                            />
                          </div>
                        )}
                        {/* {props.type === "Processed" && (
                  <div className="est-delivery">
                    Estimate Delivery{" "}
                    {moment(item.delivery_date_to).format("DD-MM-YYYY")}
                  </div>
                )} */}
                      </div>
                      <div className="prod-detail product-orderinfo product-info-detail-wrap cancelled-tab">
                        <>
                          {props.type !== "Cancelled" ? (
                            <>
                              <Link
                                className="prod-link"
                                key={i}
                                to={(location) => ({
                                  pathname: `/profile/seller-order/order-detail/${payload.orderId}/${payload.type}`,
                                  state: { ...location.state, payload },
                                })}
                              >
                                <div className="prod-title product-switch">
                                  <span className="prod-text sub-head">
                                    {item.product_detail.productName}{" "}
                                    {item.product_detail.productGrade}&nbsp;
                                  </span>
                                </div>

                                <div className="home-detail qty-pricing">
                                  <div className="home-details">
                                    <span className="prod-text bold quty">
                                      Quantity:&nbsp;
                                    </span>
                                    <span className="prod-text">
                                      {" "}
                                      {item.order_item_qty}mt
                                    </span>
                                  </div>
                                  {item.unit_price && (
                                    <div className="pro-price">
                                      <span className="prod-text bold quty">
                                        Price (PMT):&nbsp;
                                      </span>
                                      <span className="prod-text">
                                        {RupeesIcon}
                                        {item.unit_price}
                                      </span>
                                    </div>
                                  )}
                                  <div className="pro-price">
                                    <span className="prod-text bold quty">
                                      Total Price:&nbsp;
                                    </span>
                                    <span className="prod-text">
                                      {RupeesIcon} {item.total_order_amount}
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            </>
                          ) : (
                            <>
                              <div className="prod-title product-switch">
                                <span className="prod-text sub-head">
                                  {item.product_detail.productName}{" "}
                                  {item.product_detail.productGrade}&nbsp;
                                </span>
                              </div>

                              <div className="home-detail qty-pricing">
                                <div className="home-details">
                                  <span className="prod-text bold quty">
                                    Quantity:&nbsp;
                                  </span>
                                  <span className="prod-text">
                                    {" "}
                                    {item.order_item_qty}mt
                                  </span>
                                </div>
                                {item.unit_price && (
                                  <div className="pro-price">
                                    <span className="prod-text bold quty">
                                      Price (PMT):&nbsp;
                                    </span>
                                    <span className="prod-text">
                                      {RupeesIcon}
                                      {item.unit_price}
                                    </span>
                                  </div>
                                )}
                                <div className="pro-price">
                                  <span className="prod-text bold quty">
                                    Total Price:&nbsp;
                                  </span>
                                  <span className="prod-text">
                                    {RupeesIcon} {item.total_order_amount}
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      </div>

                      <div className="prod-title prod-toggle product-detail-completed">
                        {props.type !== "Cancelled" ? (
                          <div className="del-details">
                            {console.log("payloadpayload", payload)}
                            <Link
                              key={i}
                              to={(location) => ({
                                pathname: `/profile/seller-order/order-detail/${payload.orderId}/${payload.type}`,
                                state: { ...location.state, payload },
                              })}
                              className="completed-success"
                            >
                              {props.type === "Completed" ? (
                                <>
                                  <strong className="delivery-date">
                                    Delivered on{" "}
                                    {moment(item.current_status_time).calendar({
                                      sameDay: "DD/MM/YYYY h:mm a",
                                      nextDay: "DD/MM/YYYY h:mm a",
                                      nextWeek: "DD/MM/YYYY h:mm a",
                                      lastDay: "DD/MM/YYYY h:mm a",
                                      lastWeek: "DD/MM/YYYY h:mm a",
                                      sameElse: "DD/MM/YYYY h:mm a",
                                    })}
                                  </strong>
                                </>
                              ) : props.type === "Processed" ? (
                                <>
                                  <strong className="delivery-date">
                                    Item request received on{" "}
                                    {moment().format("Do MMMM YYYY h:mm a")}
                                    {/*24-sept{moment(item.createdAt).format("MMMM Do YYYY h:mm a")} */}
                                  </strong>
                                </>
                              ) : props.type === "Cancelled" ? (
                                <>
                                  <strong className="delivery-date">
                                    Cancelled on{" "}
                                    {moment(item.updatedAt).calendar({
                                      sameDay: "DD/MM/YYYY h:mm a",
                                      nextDay: "DD/MM/YYYY h:mm a",
                                      nextWeek: "DD/MM/YYYY h:mm a",
                                      lastDay: "DD/MM/YYYY h:mm a",
                                      lastWeek: "DD/MM/YYYY h:mm a",
                                      sameElse: "DD/MM/YYYY h:mm a",
                                    })}
                                    {/*24-sept {moment(item.cancel_time).format("MMMM Do YYYY h:mm a")} */}
                                  </strong>
                                  <span> Your item has been cancelled </span>
                                </>
                              ) : props.type === "Returned" ? (
                                <>
                                  <strong>
                                    Return request on{" "}
                                    {moment().format("Do MMMM YYYY h:mm a")}
                                    {/*24-sept {moment(item.Returned_time).format("MMMM Do YYYY h:mm a")} */}
                                  </strong>
                                  <span> Item returned</span>
                                </>
                              ) : null}

                              {/* 24 sept {item.odid && <p>Order ID: {item.odid} </p>} */}
                              <div className="status-details status-order-wrapper">
                                <div className="order-status-text">
                                  Order status:
                                </div>
                                <span
                                  className={getClass(
                                    item.current_status || item.order_status
                                  )}
                                  style={{
                                    color: item.order_status == "Processed"? "#f5c139" :item.order_status == "GoodsReceived" ?"green":"inherit",
                                  }}
                                >
                                  {item.order_status === "GoodsReceived" ? 'Completed' : item.order_status === "GoodDispatched" ? 'Goods Dispatched': item.order_status === 'Processed' ? 'Processing' : item.order_status}
                                </span>
                              </div>

                              <div className="rating-msg rating-msz-wrapper">
                                {/* {(props.type === "Completed" ||
                                  props.type === "Returned") && (
                                  <div className="rating-star rating-star-new">
                                    <ul className="ratings ratings-new">
                                      <Rating
                                        val={
                                          item.reviewData[0]
                                            ? item.reviewData[0].rating
                                            : 0
                                        }
                                      />
                                    </ul>
                                  </div>
                                )} */}

                                {item.customer_details.customer_id !==
                                  item.seller_details._id && (
                                  <Link
                                    className="shop-now msg-btn rating-msz-btn d-none"
                                    to={{
                                      pathname: "/chats/all",
                                      state: {
                                        chatPayload: {
                                          sender_id:
                                            item.customer_details.customer_id,
                                          product_id: item.product_id,
                                        },
                                      },
                                    }}
                                    data-count={item.messageCount}
                                  >
                                    <i
                                      className="fas fa-envelope icon-chat-new"
                                      data-count={0}
                                    />
                                  </Link>
                                )}
                              </div>
                            </Link>
                          </div>
                        ) : (
                          <div className="del-details">
                            {props.type === "Completed" ? (
                              <>
                                <strong className="delivery-date">
                                  Delivered on{" "}
                                  {moment(item.current_status_time).calendar({
                                    sameDay: "h:mm a",
                                    nextDay: "[Tomorrow] h:mm a",
                                    nextWeek: "dddd",
                                    lastDay: "[Yesterday] h:mm a",
                                    lastWeek: "DD/MM/YYYY h:mm a",
                                    sameElse: "DD/MM/YYYY h:mm a",
                                  })}
                                </strong>
                              </>
                            ) : props.type === "Processed" ? (
                              <>
                                <strong>
                                  Item request received on{" "}
                                  {moment().format("Do MMMM YYYY h:mm a")}
                                  {/*24-sept{moment(item.createdAt).format("MMMM Do YYYY h:mm a")} */}
                                </strong>
                              </>
                            ) : props.type === "Cancelled" ? (
                              <>
                                <strong className="delivery-date">
                                  Cancelled on{" "}
                                  {moment(item.updatedAt).calendar({
                                    sameDay: "DD/MM/YYYY h:mm a",
                                    nextDay: "DD/MM/YYYY h:mm a",
                                    nextWeek: "DD/MM/YYYY h:mm a",
                                    lastDay: "DD/MM/YYYY h:mm a",
                                    lastWeek: "DD/MM/YYYY h:mm a",
                                    sameElse: "DD/MM/YYYY h:mm a",
                                  })}
                                  {/*24-sept {moment(item.cancel_time).format("MMMM Do YYYY h:mm a")} */}
                                </strong>
                                {/* <span> Your item has been cancelled </span> */}
                              </>
                            ) : props.type === "Returned" ? (
                              <>
                                <strong>
                                  Return request on{" "}
                                  {moment().format("Do MMMM YYYY h:mm a")}
                                  {/*24-sept {moment(item.Returned_time).format("MMMM Do YYYY h:mm a")} */}
                                </strong>
                                <span> Item returned</span>
                              </>
                            ) : null}

                            {/* 24 sept {item.odid && <p>Order ID: {item.odid} </p>} */}
                            <div className="status-details status-order-wrapper">
                              <div className="order-status-text">
                                Order status:
                              </div>
                              <span
                                className={getClass(
                                  item.current_status || item.order_status
                                )}
                              >
                                Cancelled
                                {/* {item.current_status == "ProformaInvoice" ? "Proforma Invoice" :
                                  item.current_status == "ReadyToShip" ? "Ready To Ship" :
                                    item.current_status == "GoodsDispatched" ? "Goods Dispatched" :
                                      item.current_status == "OrderComplete" ? "Order Complete" :
                                        item.current_status == "GoodsReceived" ? "Goods Received" :
                                          item.current_status
                                } */}
                              </span>
                            </div>

                            <div className="rating-msg rating-msz-wrapper">
                              {(props.type === "Completed" ||
                                props.type === "Returned") && (
                                <div className="rating-star rating-star-new">
                                  <ul className="ratings">
                                    <Rating
                                      val={
                                        item.reviewData[0]
                                          ? item.reviewData[0].rating
                                          : 0
                                      }
                                    />
                                  </ul>
                                </div>
                              )}

                              {/* {item.customer_details.customer_id !==
                                item.seller_details._id && (
                                <Link
                                  className="shop-now msg-btn"
                                  to={{
                                    pathname: "/chats/all",
                                    state: {
                                      chatPayload: {
                                        sender_id:
                                          item.customer_details.customer_id,
                                        product_id: item.product_id,
                                      },
                                    },
                                  }}
                                  data-count={item.messageCount}
                                >
                                  <i
                                    className="fas fa-envelope icon-chat-new"
                                    data-count={0}
                                  />
                                </Link>
                              )} */}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </>
            );
          }
        })
      )}

      {!isEmpty(props.myProduct) && props.myProduct.product.length === 0 && (
        <div className="product-head">
          <div className="product-img">
            <img src="\assets\images\no-product.png" alt="img"></img>
          </div>
          <h6> No Product in your Sale list </h6>
        </div>
      )}
      {console.log("props", props)}

      {!isEmpty(props.myProduct) &&
      // props.myProduct.product.length < props.myProduct.productCount &&
      // props.myProduct.product.length >= 12 ?
      props.myProduct.product.length < props.myProduct.productCount ? (
        <div className="plp-loadMore-div">
          <button className="shop-now" onClick={loadMore}>
            {buttonLoader ? <ButtonLoader content={"Loading"} /> : "Load more"}
          </button>
        </div>
      ) : null}

      {open && (
        <div className="make-offer-popup delivery-infopopup">
          <div className="offer-content">
            <button
              className="modal-closeBtn"
              onClick={() => closePopup()}
              type="button"
            >
              <i className="fal fa-times-circle"></i>
            </button>

            <form onSubmit={relistHandler}>
              <div className="sellprice-infowrap">
                <h2>Define price and shipping.</h2>
                <div className="sellprice-wrap">
                  <input
                    name="sellPrice"
                    required
                    onChange={(e) => {
                      let checkPrice = new RegExp(/^\d*\.?\d*$/);
                      if (checkPrice.test(e.target.value)) {
                        setSellPrice(e.target.value);
                      }
                    }}
                    value={sellPrice}
                    type="text"
                    placeholder="Sell Price"
                  />
                </div>

                <div className="sellprice-wrap">
                  <Select
                    options={deliveryOption}
                    placeholder="Delivery Option"
                    value={deliveryType}
                    onChange={(e) => setDeliveryType(e)}
                    classNamePrefix={"select-opt"}
                  />
                </div>
                <div className="shipping-info">
                  <div className="input-contain">
                    <input
                      type="number"
                      placeholder={"Quantity"}
                      name="Quantity"
                      value={Quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                      required
                      min={1}
                    />
                  </div>
                </div>
                {deliveryType.value === "External" && (
                  <div className="shipping-info">
                    <label className="checkbox-wrap">
                      <input type="checkbox" onChange={checkboxHandler}></input>
                      <span className="checkmark"></span>
                      is Delivery Free?
                    </label>
                    <div className="input-contain">
                      <label className="label-txt">Delivery Charges</label>
                      <input
                        type="text"
                        disabled={isDeliveryFree}
                        placeholder={RupeesIcon}
                        name="deliveryCharges"
                        value={isDeliveryFree ? "Free" : deliveryCharges}
                        onChange={(e) => {
                          let checkPrice = new RegExp(/^\d*\.?\d*$/);
                          if (checkPrice.test(e.target.value)) {
                            setDeliveryCharges(e.target.value);
                          }
                        }}
                        required
                      />
                    </div>
                    <div className="input-contain">
                      <label className="label-txt">Delivery Time</label>
                      <input
                        type="text"
                        placeholder="Ex. 3-5"
                        name="deliveryTime"
                        value={deliveryTime}
                        onChange={(e) => setDeliveryTime(e.target.value)}
                        required
                      />
                    </div>
                    {/* <div className="input-contain">
                      <label className="label-txt">Shipping Method</label>
                      <input
                        type="text"
                        placeholder="Courier Name"
                        name="ShippingMethod"
                        value={ShippingMethod}
                        onChange={(e) => setShippingMethod(e.target.value)}
                        required
                      />
                    </div> */}
                  </div>
                )}
                <button className="relist-btn coupon-btn">Re List</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListingProduct;
