import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { latestSellProduct } from "../../store/actions/productDetails";
import moment from "moment";

const TableData = (props) => {
  const dispatch = useDispatch();
  const [tableData, setDataTable] = useState([]);
  useEffect(() => {
    if (props.details) {
      dispatch(
        latestSellProduct(
          { productName: props.details.data[0].productName },
          (status) => {
            if(!status.length) return;

            const products = status.filter((product) => {
              return (
                product.categoryData.name == "Lumps" &&
                ["Advance Payment", "Next Day"].includes(
                  product.product_detail.paymentType
                ) &&
                product.product_detail.productName != "Manganese Ore"
              );
            });
            setDataTable(products);
          }
        )
      );
    }
  }, [props.details]);

  const formattedDate = (createdAt) => {
    const createdAtT = moment.utc(createdAt);
    const today = moment().startOf('day');
    return createdAtT.isSame(today, 'day')
      ? createdAtT.format('h:mm a')
      : createdAtT.format('DD/MM/YYYY');
  }

  return (
    <section className="product-specification-wrapper">
      {props.details &&
        props.details.data[0].selfUserData[0].sellerAcceptance == "accept" ? (
        <>
          <h2>Recent Trades</h2>
          <div className="product-list-table-wrapper">
            <div className="product-list-main-wrap">
              <ul className="product-table-head">
                <li className="product-head">Product</li>
                <li className="category-title new-cat-title">Product Type</li>
                <li className="size-head">Size (mm)</li>
                <li className="ltp-head">Price PMT (INR)</li>
                <li className="qty-head">QTY (MT)</li>
                <li className="qty-head">Delivery Type</li>
                <li className="city-head">City</li>
                <li className="time-head">Time</li>
              </ul>
              <div className="product-table-wrap">
                {tableData.map((item, index) => {
                  return (
                    <ul key={index} className="product-table-values">
                      <li className="product-title">
                        {item.product_detail.productName}
                        {item.product_detail.productGrade ? "(" + item.product_detail.productGrade + ")" : null}
                      </li>
                      {item.categoryData ? (
                        <li className="category-title">
                          {item.categoryData.name}
                        </li>
                      ) : (
                        ""
                      )}

                      <li className="size-title">
                        {item.productSize ? item.productSize : item.product_detail.productSize}
                      </li>
                      <li className="ltp-title">
                        {(item.product_detail && item.product_detail.offerDetails && item.product_detail.offerDetails.final_offer_price) ? parseInt(item.product_detail.offerDetails.final_offer_price) :  parseInt(item.unit_total_price.$numberDecimal)}
                      </li>
                      <li className="qty-title">
                        {item.order_item_qty}
                      </li>
                      <li className="qty-title">
                        {item.deliveryType ? item.deliveryType : item.product_detail.deliveryType}
                      </li>
                      <li className="city-title">
                        {
                          item.product_detail.deliveryType === 'FOR' ? (
                            item.address_details && item.address_details.length && item.address_details[0] && 
                            item.address_details[0].billing_city.charAt(0).toUpperCase() + 
                            item.address_details[0].billing_city.slice(1)
                          ) : (
                            item.address_details_seller && item.address_details_seller.length && item.address_details_seller[0] && 
                            item.address_details_seller[0].billing_city.charAt(0).toUpperCase() + 
                            item.address_details_seller[0].billing_city.slice(1)
                          )
                        }
                      </li>
                      <li className="time-title">
                        {formattedDate(item.createdAt)}
                      </li>
                    </ul>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </section>
  );
};

export default TableData;
