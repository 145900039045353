import React, { useState } from "react";
import ListingProduct from "./listingProduct";
import { SpinnerLoader } from "../../../../utils/constants";
import { useLocation, Link, Switch, Route, Redirect } from "react-router-dom";
// import { verifyProfile,getUserAddedProduct  } from '../../../store/actions/profile';
import { connect } from "react-redux";
import {
  getorderlist,
  cancelThisOrder,
  returnThisOrder,
  getReasonList,
  getAllReviewByUserId,
} from "../../../../store/actions/profile";
import Toast from "light-toast";
import { useToasts } from "react-toast-notifications";
import { myOrderRoutes } from "../profileRoutes";
import OrderDetails from "../../../OrderDetails";
import { get } from "lodash";
import { useHistory } from "react-router";
import { ReviewPoup } from "../../../OrderDetails";
import { addReviewOnProduct } from "../../../../store/actions/profile";
import Loader from "../../../Loader";

const Buying = (props) => {
  const [innerLoader, setInnerLoader] = React.useState(false);
  const [reviewPopup, setReviewPopup] = React.useState(false);
  const [reviewData, setReviewData] = React.useState(false);
  const [comment, setComment] = React.useState("");
  const [rating, setRating] = React.useState(0);
  const [heading, setHeading] = React.useState("");
  const [downloadLink, setDownloadLink] = React.useState("");
  const [deletePopup, setdeletePopup] = React.useState(false);
  const [prodId, setprodId] = React.useState("");
  const location = useLocation();
  const { addToast } = useToasts();
  const history = useHistory();
  const [showLoader, setShowLoader] = React.useState(false);
  const [loaderMessage, setLoaderMessage] = React.useState("");

  const [searchOrder, setSearchOrder] = useState("");

  React.useEffect(() => {
    setShowLoader(true);
    setLoaderMessage("Please wait...");
    setTimeout(() => setShowLoader(false), 2500);
  }, [location.state]);

  const cancelProduct = (paylaod, data) => {
    setShowLoader(true);
    setLoaderMessage("Please wait...");
    props.cancelThisOrder(paylaod, data, (status) => {
      if (status) {
        setShowLoader(false);
        addToast("Your order has been cancelled.", {
          appearance: "success",
          autoDismissTimeout: 2500,
        });
      } else {
        addToast("Cancel request failed", {
          appearance: "error",
          autoDismissTimeout: 2500,
        });
        setShowLoader(false);
      }
    });
  };

  const returnProduct = (paylaod, data) => {
    ////Toast.loading("Processing...");
    props.returnThisOrder(paylaod, data, (status) => {
      if (status) {
        addToast("Return request submit", {
          appearance: "success",
          autoDismissTimeout: 2500,
        });
        Toast.success("Success", 1000);
      } else {
        addToast("Return request failed", {
          appearance: "error",
          autoDismissTimeout: 2500,
        });
        Toast.fail("Failed", 1000);
      }
    });
  };

  /////// REview Pop  /////////////
  const submitReview = (editId) => {
    var getReviewProuctId = localStorage.getItem("ReviewProductId");
    var users = props.orderlist.Completed.product.filter(
      (product) => product.product_id == getReviewProuctId
    );
    var seller_Id = users[0].seller_details._id;
    var userId = users[0]._id;
    if (comment.trim().length < 1 || rating === 0) {
      return Toast.fail("Please fill all the fields and select rating", 2000);
    } else {
      Toast.loading("Submitting...");
      const payload = {
        product_id: getReviewProuctId,
        seller_id: seller_Id,
        user_id: userId,
        rating,
        comment,
        heading: "Good Product",
        review_from: "Web",
      };

      // eslint-disable-next-line no-unused-expressions
      editId
        ? props.editReview({ ...payload, id: editId }, (status) => {
            if (status)
              Toast.success("Review Updated ", 1000, () => {
                props.getAllReviewByUserId({ reviewBy: "User", page: 1 }); //userId: props.userId
                // props.loadMore({--------------------
                //   [props.type === "Review" ? 'userId' : 'sellerId']: props.userId,
                //   page: 1,
                // });----------------
                setReviewPopup(false);
              });
            else Toast.fail("Failed", 1000, () => setReviewPopup(false));
          })
        : props.addReview(payload, (status) => {
            if (status)
              Toast.success("Review Added ", 1000, () => {
                props.getAllReviewByUserId({ reviewBy: "User", page: 1 }); //userId: props.userId,y
                // props.loadMore({
                //   [props.type === "Review" ? 'userId' : 'sellerId']: props.userId,
                //   page: 1,
                // });
                setReviewPopup(false);
              });
            else Toast.fail("Failed", 1000, () => setReviewPopup(false));
          });
    }
  };

  
  const tabRender = () => (
    <Switch>
      <Route
        path={`/profile/myorder/order-detail/:prodId/:prodType`}
        // path={`${location.pathname}/order-detail`}
        exact
        render={(prev) => <OrderDetails {...prev} />}
        />
      {myOrderRoutes.map((data, key) => {
        if (data.prefix === "/profile/myorder") {
          return (
            <Route
            path={data.prefix + data.pathname}
            render={(prev) => (
                <ListingProduct
                  myProduct={
                    props.orderlist ? props.orderlist[data.state.tab] : {}
                  }
                  type={data.state.tab}
                  userId={props.userId}
                  loadMore={props.getorderlist}
                  reasonList={props.getReasonList}
                  cancelProduct={cancelProduct}
                  returnProduct={returnProduct}
                  {...prev}
                  // productCount={}
                  downloadLink={downloadLink}
                  setReviewPopup={setReviewPopup}
                  setdeletePopup={setdeletePopup}
                  reviewData={reviewData}
                  ProductId={setprodId}
                />
              )}
            />
          );
        }
      })}
      <Redirect from="/profile/myorder" to="/profile/myorder/pending" />
    </Switch>
  );

  const handleSearch = () => {
    props.getorderlist(
      {
        page: 1,
        type: location && location.state && location.state.tab,
        searchBy: searchOrder,
      },
      ""
    );
  };

  return (
    <div style={{ position: "relative" }}>
      {showLoader ? <Loader message={loaderMessage} /> : null}
      <div
        className={"blogtags-sec" + (innerLoader ? " spinner-time-div" : "")}
      >
        {(location.pathname === "/profile/myorder/pending" ||
          location.pathname === "/profile/myorder/processed" ||
          location.pathname === "/profile/myorder/cancelled" ||
          location.pathname === "/profile/myorder/completed") && (
          <div className="tags-wrap">
            <div className="container">
              <div className="tags 78 order-tabs">
                {myOrderRoutes.map((data, indx) => (
                  <Link
                    key={indx}
                    to={{
                      pathname: data.prefix + data.pathname,
                      state: data.state,
                    }}
                    className={
                      data.pathname ===
                      location.pathname.replace("/profile/myorder/", "/")
                        ? "active-tab"
                        : ""
                    }
                  >
                    {data.name}
                  </Link>
                ))}
                <div className="search-order">
                  {props &&
                  props.location &&
                  props.location.state &&
                  props.location.state.tab &&
                  props.orderlist &&
                  props.orderlist[props.location.state.tab] &&
                  props.orderlist[props.location.state.tab].csv ? (
                    <a
                      href={props.orderlist[props.location.state.tab].csv}
                      className="download-orders"
                      title="Download orders"
                    >
                      <i className="fas fa-download"></i>
                    </a>
                  ) : props.location.pathname === "/profile/myorder/pending" &&
                    props &&
                    props.orderlist &&
                    props.orderlist.Pending &&
                    props.orderlist.Pending.csv ? (
                    <a
                      href={props.orderlist.Pending.csv}
                      className="download-orders"
                      title="Download orders"
                    >
                      <i className="fas fa-download"></i>
                    </a>
                  ) : null}
                  <input
                    className="search-order-input"
                    type="text"
                    placeholder="Search order...."
                    onChange={(e) => setSearchOrder(e.target.value)}
                  />
                  <button
                    className="search-btn-mob top-search-btn"
                    onClick={handleSearch}
                  >
                    <i className="search-btn-icon"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="container">{tabRender()}</div>
        {reviewPopup ? (
          <ReviewPoup
            onClose={() => setReviewPopup(false)}
            {...reviewData}
            submit={submitReview}
            newRating={rating}
            setRating={setRating}
            newComment={comment}
            setComment={setComment}
            heading={heading}
            setHeading={setHeading}
          />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    orderlist: state.userProfile.orderlist,
    userId: state.userInfo.loggedUserInfo.userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getorderlist: (payload, cancelToc) =>
      dispatch(getorderlist(payload, cancelToc)),
    addReview: (payload, status) =>
      dispatch(addReviewOnProduct(payload, status)),
    cancelThisOrder: (paylaod, data, status) =>
      dispatch(cancelThisOrder(paylaod, data, status)),
    returnThisOrder: (paylaod, data, status) =>
      dispatch(returnThisOrder(paylaod, data, status)),
    getAllReviewByUserId: (paylaod) => dispatch(getAllReviewByUserId(paylaod)),
    getReasonList: (payload, callback) => getReasonList(payload, callback),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Buying);
